<template>
    <div>
        <header>
            <router-link :to="{ name: 'TurnierDetail', params: { turnierID: turnierID }}">
                <button v-show="!searchActive" class="btn-unstyled btn-back">
                    <i class="fas fa-chevron-left"></i>
                </button>
            </router-link>
            <h2 v-show="!searchActive" :class="(!searchActive && searchButtonClicked) ? 'fadeInDownSlow' : ''">{{lang.listTeams.headline}}</h2>
            <button v-show="searchActive" class="btn-unstyled btn-search">
                <i class="fas fa-search"></i>
            </button>
            <transition
                enter-active-class="fadeInRight"
                leave-active-class="fadeOutRight">
                <input ref="search" class="search-input" v-model="searchWord" v-if="searchActive" :placeholder="lang.listTeams.searchInputPlacholder" type="text" :name="lang.listTeams.searchInputLabel" id="search">
            </transition>
            <button class="btn-unstyled btn-open-search " @click="search()">
            <i v-show="searchActive" class="fas fa-times"></i>
            <i v-show="!searchActive" class="fas fa-search"></i>
            </button>
        </header>
        <div class="container team-list-container">
            <p v-if="!isTeamArrayEmpty" class="description">{{admin ? lang.listTeams.descriptionAdmin : lang.listTeams.descriptionPlayer}}</p>
            <p v-if="!isTeamArrayEmpty">{{lang.listTeams.NumberOfTeamsActive}} {{teamsLength}}/{{teamsAllowedInTurnier}}</p>
            <div v-show="teams.length == 0 && searchActive">
                <md-empty-state
                    md-icon="search_off"
                    :md-label="lang.listTeams.emptyStateLabelSearch"
                    :md-description="lang.listTeams.emptyStateDescriptionSearch">
                </md-empty-state>
            </div>
            <div v-show="isTeamArrayEmpty && !searchActive && isDataLoaded">
                <md-empty-state
                    md-icon="people_alt"
                    :md-label="lang.listTeams.emptyStateLabel"
                    :md-description="admin ? lang.listTeams.emptyStateDescriptionAdmin : lang.listTeams.emptyStateDescriptionPlayer">
                </md-empty-state>
            </div>
            <div v-show="!isTeamArrayEmpty">
                <transition appear name="fadeRight" mode="out-in">
                    <div>
                        <ul class="ul-unstyled team-list">
                            <li v-for="t in teams" :key="t.team.teamID" class="team-detail-item card">
                                <div class="img-container">
                                    <md-avatar class="md-avatar-icon md-accent">{{t.team.teamName.charAt(0).toUpperCase()}}</md-avatar>
                                </div>
                                <div class="content">
                                    <span class="headline">{{t.team.teamName}} </span>
                                    <div class="player" v-if="t.team.teamAufbau !== undefined && t.team.teamAufbau.length > 0">
                                        <div class="player-content" v-if="t.team.teamAufbau[0].spieler1 !== undefined && t.team.teamAufbau[0].teamSpielerID.spielerID1 !== 1">
                                            <i class="fas fa-user"></i>
                                            <span class="player-first">{{t.team.teamAufbau[0].spieler1.vorname}}</span>
                                        </div>
                                        <div class="player-content" v-if="t.team.teamAufbau[0].spieler2 !== undefined && t.team.teamAufbau[0].teamSpielerID.spielerID2 !== 1">
                                            <i class="fas fa-user"></i>
                                            <span class="player-second">{{t.team.teamAufbau[0].spieler2.vorname}}</span>
                                        </div>
                                        <div v-if="!isTurnierBeendet && isOnline" class="button-list-teams">
                                            <button class="team-button btn-unstyled"
                                                v-if="getPlayerTeamNumber == t.team.teamID && (isTurnierStatusErstellt || isTurnierStatusTurnierPlanErstellt)" 
                                                @click="activeConfirmDialogLeave=true, joinTeamID=t.team.teamID, 
                                                                joinSpielerID_1=t.team.teamAufbau[0].teamSpielerID.spielerID1, 
                                                                joinSpielerID_2=t.team.teamAufbau[0].teamSpielerID.spielerID2">
                                                <i class="fas fa-user-minus"></i>
                                            </button>
                                            <button class="team-button btn-unstyled"
                                                v-if="isJoinTeamAllowed(t.team.teamAufbau)"
                                                @click="activeConfirmDialogJoin=true, joinTeamID=t.team.teamID, 
                                                        joinSpielerID_1=t.team.teamAufbau[0].teamSpielerID.spielerID1, 
                                                        joinSpielerID_2=t.team.teamAufbau[0].teamSpielerID.spielerID2">
                                                <i class="fas fa-user-plus"></i>
                                            </button>
                                            <button v-if="admin" class="team-button btn-unstyled"
                                                @click="activePromptDialogEditTeamByAdmin=true, newTeam=JSON.parse(JSON.stringify(t.team))">
                                                <i class="fas fa-pencil-alt"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </transition>
            </div>
            <div v-if="isOnline">
                <div v-if="getPlayerTeamNumber == 0 || admin">
                    <div v-if="!isTurnierBeendet">
                        <div v-if="!teamNumberReached">
                            <div class="add-link" @click="newTeam={}, activePromptDialogNew=true">
                                <md-button class="add-link md-icon-button md-raised md-primary md-filled md-fab">
                                    <md-icon>add</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div v-else>
                            <div class="add-link" @click="showInformationModal()">
                                <md-button class="add-link md-icon-button md-raised md-primary md-filled md-fab">
                                    <md-icon>add</md-icon>
                                </md-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="add-link" @click="activePromptDialogEditTeamByPlayer=true, 
                    newTeam.teamName = getTeamByTeamID[0].team.teamName.trim(), newTeam.teamID = getPlayerTeamNumber">
                    <md-button class="add-link md-icon-button md-raised md-primary md-filled md-fab">
                        <md-icon>create</md-icon>
                    </md-button>
                </div>
            </div>
            <md-dialog :md-active.sync="activePromptDialogEditTeamByAdmin" :md-fullscreen=false>
                <div class="dialog-content dialog-team">
                    <div class="headline-container">
                        <p class="headline p-unstyled">{{lang.listTeams.teamEditDialogHeadline}}</p>
                        <button class="btn-unstyled" @click="activeConfirmDialogDeleteTeam=true, activePromptDialogEditTeamByAdmin=false"><i class="fas fa-trash-alt"></i></button>
                    </div>
                    <form @submit.prevent="putTeam()" id="changeTeamForm">
                        <md-field>
                            <label>{{lang.listTeams.labelTeamName}}</label>
                            <md-input type="text" maxlength="28" v-model.trim="newTeam.teamName" :name="lang.listTeams.labelTeamName"></md-input>
                        </md-field>
                        <div class="player-list" v-if="newTeam.teamAufbau !== undefined && newTeam.teamAufbau.length > 0">
                            <div class="player" :class="deletePlayer1 ? 'delete' : ''" v-if="newTeam.teamAufbau[0].spieler1 !== undefined && newTeam.teamAufbau[0].teamSpielerID.spielerID1 !== 1">
                                <div class="player-content">
                                    <i class="fas fa-user"></i>
                                    <span class="player-first">{{newTeam.teamAufbau[0].spieler1.vorname}}</span>
                                </div>
                                <div class="remove">
                                    <button type="button" class="btn-unstyled" @click="removePlayer(1)">
                                        <i v-if="deletePlayer1" class="fas fa-plus"></i>
                                        <i v-else class="fas fa-minus"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="player" :class="deletePlayer2 ? 'delete' : ''" v-if="newTeam.teamAufbau[0].spieler2 !== undefined && newTeam.teamAufbau[0].teamSpielerID.spielerID2 !== 1">
                                <div class="player-content">
                                    <i class="fas fa-user" ></i>
                                    <span class="player-second">{{newTeam.teamAufbau[0].spieler2.vorname}}</span>
                                </div>
                                <div class="remove">
                                    <button type="button" class="btn-unstyled" @click="removePlayer(2)">
                                        <i v-if="deletePlayer2" class="fas fa-plus"></i>
                                        <i v-else class="fas fa-minus"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <md-dialog-actions>
                            <md-button @click="activePromptDialogEditTeamByAdmin=false, deletePlayer1=false, deletePlayer2=false" 
                                >{{lang.listTeams.buttonDialogCancel}}</md-button>
                            <md-button type="submit" @click="activePromptDialogEditTeamByAdmin=false" 
                                class="md-primary">{{lang.listTeams.buttonDialogAgree}}</md-button>
                        </md-dialog-actions>
                    </form>
                </div>
            </md-dialog>
            <md-dialog-confirm
                :md-active.sync="activeConfirmDialogJoin"
                :md-title="lang.listTeams.dialogHeadlineJoin" dialogHeadline
                :md-confirm-text="lang.listTeams.buttonDialogAgree"
                :md-cancel-text="lang.listTeams.buttonDialogCancel"
                @md-confirm="putTeamAufbau()" />
            <md-dialog-confirm
                :md-active.sync="activeConfirmDialogLeave"
                :md-title="lang.listTeams.dialogHeadlineLeave" dialogHeadline
                :md-confirm-text="lang.listTeams.buttonDialogAgree"
                :md-cancel-text="lang.listTeams.buttonDialogCancel"
                @md-confirm="putTeamAufbau()" />
            <md-dialog-confirm
                :md-active.sync="activeConfirmDialogDeleteTeam"
                :md-title="lang.listTeams.dialogHeadlineDeleteTeam" dialogHeadline
                :md-confirm-text="lang.listTeams.buttonDialogAgree"
                :md-cancel-text="lang.listTeams.buttonDialogCancel"
                @md-confirm="deleteTeam()" />
            <md-dialog-prompt
                :md-active.sync="activePromptDialogNew"
                v-model.trim="newTeam.teamName"
                :md-title="lang.listTeams.dialogHeadlineTeam"
                :md-input-maxlength="28"
                :md-input-placeholder="lang.listTeams.dialogPlaceholderTeam"
                :md-cancel-text="lang.listTeams.buttonDialogCancel"
                :md-confirm-text="lang.listTeams.buttonDialogAgree"
                @md-confirm="createTeam()"/>
            <md-dialog-prompt
                :md-active.sync="activePromptDialogEditTeamByPlayer"
                v-model.trim="newTeam.teamName"
                :md-title="lang.listTeams.dialogHeadlineTeam"
                :md-input-maxlength="28"
                :md-input-placeholder="lang.listTeams.dialogPlaceholderTeam"
                :md-cancel-text="lang.listTeams.buttonDialogCancel"
                :md-confirm-text="lang.listTeams.buttonDialogAgree"
                @md-confirm="putTeam()"/>
            <SubmitModal/>
            <InformationModal/>
        </div>
    </div>
</template>

<script>
import store from '@/store/index'
import SubmitModal from '../Utils/SubmitModal'
import InformationModal from '../Utils/InformationModal'
import { VueOfflineMixin } from 'vue-offline'

export default {
    name: 'ListTeamsByTurnier',
    props: ['turnierID'],
    mixins: [VueOfflineMixin],
    components: {
        SubmitModal,
        InformationModal
    },
    data() {
        return {
            searchActive: false,
            searchButtonClicked: false,
            searchWord: '',
            activeConfirmDialogJoin: false,
            activeConfirmDialogLeave: false,
            activeConfirmDialogDeleteTeam: false,
            activePromptDialogNew: false,
            activePromptDialogEditTeamByPlayer: false,
            activePromptDialogEditTeamByAdmin: false,
            newTeam: {
                teamName: ""
            },
            joinTeamID: 0,
            joinSpielerID_1: 0,
            joinSpielerID_2: 0,
            deletePlayer1: false,
            deletePlayer2: false
        }
    },
    created() {
        if (store.getters.getTeams.length == 0) {
            store.dispatch('getSingleTurnier', this.turnierID);
        }
        if (Object.keys(store.getters.getSpielerData) == 0) {
            store.dispatch('getSpielerData');
        }
        store.dispatch('getTeamsByTurnierID', this.turnierID);
    },
    methods: {
        search() { 
            this.searchButtonClicked = true;
            this.searchActive = this.searchActive ? false: true;
            if (!this.searchActive) {
                this.searchWord = '';
            } else {
                this.$nextTick(() => this.$refs.search.focus())
            }
        },
        createTeam() {
            if (this.admin) {
                this.postTeamEmptyAdmin();
            } else {
                this.postTeam();
            }
        },
        async putTeamAufbau() {
            const data = {teamID: this.joinTeamID, turnierID: this.turnierID}
            await store.dispatch('putTeamAufbau', data)
                .then(result => {
                    if (result.data != null) {
                        store.dispatch("getTeamsByTurnierID", this.turnierID);
                        this.showDialogActive(true, this.lang.listTeams.successHeadlineChangeTeamAufbau, "");
                        setTimeout(() => store.commit("SET_SHOW_SUBMIT_MODAL_STATUS", false), store.state.submitModalSuccessSeconds);
                    } else {
                        this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.team.apiReponseErrorMessage);
                    }
                })
                .catch(error => {
                    this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.team.apiReponseErrorMessage);
                });
        },
        async postTeam() {
            const data = {team: this.newTeam, turnierID: this.turnierID}
            await store.dispatch('postTeam', data)
                .then(result => {
                    if (result.data != null) {
                        store.dispatch("getTeamsByTurnierID", this.turnierID);
                        this.showDialogActive(true, this.lang.listTeams.successHeadlineCreate, "");
                        setTimeout(() => store.commit("SET_SHOW_SUBMIT_MODAL_STATUS", false), store.state.submitModalSuccessSeconds);
                    } else {
                        this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.team.apiReponseErrorMessage);
                    }
                })
                .catch(error => {
                    this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.team.apiReponseErrorMessage);
                });
        },
        async postTeamEmptyAdmin() {
            const data = {team: this.newTeam, turnierID: this.turnierID}
            await store.dispatch('postTeamEmptyAdmin', data)
                .then(result => {
                    if (result.data != null) {
                        store.dispatch("getTeamsByTurnierID", this.turnierID);
                        this.showDialogActive(true, this.lang.listTeams.successHeadlineCreate, "");
                        setTimeout(() => store.commit("SET_SHOW_SUBMIT_MODAL_STATUS", false), store.state.submitModalSuccessSeconds);
                    } else {
                        this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.team.apiReponseErrorMessage);
                    }
                })
                .catch(error => {
                    this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.team.apiReponseErrorMessage);
                });
        },
        async putTeam() {
            if (this.admin) {
                if (this.deletePlayer1) {
                    this.newTeam.teamAufbau[0].teamSpielerID.spielerID1 = 1;
                    this.newTeam.teamAufbau[0].spieler1.vorname = "";
                    this.newTeam.teamAufbau[0].spieler1.nachname = "";
                } 
                if (this.deletePlayer2) {
                    this.newTeam.teamAufbau[0].teamSpielerID.spielerID2 = 1;
                    this.newTeam.teamAufbau[0].spieler2.vorname = "";
                    this.newTeam.teamAufbau[0].spieler2.nachname = "";
                }
            }
            this.deletePlayer1=false;
            this.deletePlayer2=false;

            const data = {team: this.newTeam, turnierID: this.turnierID}
            await store.dispatch('putTeam', data)
                .then(result => {
                    if (result.data != null) {
                        store.dispatch("getTeamsByTurnierID", this.turnierID);
                        this.showDialogActive(true, this.lang.listTeams.successHeadlineEdit, "");
                        setTimeout(() => store.commit("SET_SHOW_SUBMIT_MODAL_STATUS", false), store.state.submitModalSuccessSeconds);
                    } else {
                        this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.team.apiReponseErrorMessage);
                    }
                })
                .catch(error => {
                    this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.team.apiReponseErrorMessage);
                })
        },
        async deleteTeam() {
            const data = {"teamID": this.newTeam.teamID, 'turnierID': this.turnierID}
            await store.dispatch('deleteTeam', data)
                .then(result => {
                    if (result.data != null) {
                        store.dispatch("getTeamsByTurnierID", this.turnierID);
                        this.showDialogActive(true, this.lang.listTeams.successHeadlineEdit, "");
                        setTimeout(() => store.commit("SET_SHOW_SUBMIT_MODAL_STATUS", false), store.state.submitModalSuccessSeconds);
                    } else {
                        this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.team.apiReponseErrorMessage)
                    }
                })
                .catch(error => {
                    this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.team.apiReponseErrorMessage)
                });
        },
        showDialogActive(formSuccess, formSuccessHeadline, formSuccessMessage) {
            store.commit("SET_SHOW_SUBMIT_MODAL", {
                show: true,
                success: formSuccess,
                headline: formSuccessHeadline,
                message: formSuccessMessage
            });
        },
        isTeamFull(teamSpielerID) {
            return teamSpielerID.spielerID1 != 1 && teamSpielerID.spielerID1 != undefined && 
            teamSpielerID.spielerID2 != 1 && teamSpielerID.spielerID2 != undefined ? true : false;
        },
        isJoinTeamAllowed(teamAufbau) {
            if ((this.isTurnierStatusErstellt || this.isTurnierStatusTurnierPlanErstellt || this.isTurnierGestartet) &&
                this.getPlayerTeamNumber == 0 &&
                !this.isTeamFull(teamAufbau[0].teamSpielerID) &&
                (teamAufbau[0].spieler2 == 'undefined' || teamAufbau[0].teamSpielerID.spielerID2 == 1)) {
                    return true;
                } else {
                    return false;
                }
        },
        removePlayer(playerNumber) {
            if (playerNumber == 1) {
                this.deletePlayer1 ? this.deletePlayer1 = false : this.deletePlayer1 = true;
            } else {
                this.deletePlayer2 ? this.deletePlayer2 = false : this.deletePlayer2 = true;
            }
        },
        showInformationModal() {
            store.commit("SET_SHOW_INFORMATION_DIALOG", {
                show: true,
                headline: this.admin ? this.lang.listTeams.modalTeamsFullHeadlineAdmin : this.lang.listTeams.modalTeamsFullHeadlineUser,
                message: this.admin ? this.lang.listTeams.modalTeamsFullMessageAdmin : this.lang.listTeams.modalTeamsFullMessageUser
            });
        },
    },
    computed: {
        lang() {
            return store.getters.getLanguage;
        },
        teams() {
            return store.getters.filterTeamByName(this.searchWord);
        },
        spielerID() {
            return store.getters.getSpielerID;
        },
        admin() {
            return store.getters.isSpielerTurnierAdmin(this.turnierID);
        },
        getPlayerTeamNumber() {
            return store.getters.getPlayerTeamNumber(this.spielerID);
        },
        getTeamByTeamID() {
            return store.getters.getTeamByTeamID(this.getPlayerTeamNumber);
        },
        isTeamArrayEmpty() {
            return store.getters.getTeamsLength == 0;
        },
        teamsAllowedInTurnier() {
            return store.getters.getTurnier.anzTeams;
        },
        teamsLength() {
            return store.getters.getTeamsLength;
        },
        teamNumberReached() {
            return this.teamsAllowedInTurnier == this.teamsLength ? true: false;
        },
        isTurnierBeendet() {
            return store.getters.isTurnierBeendet;
        },
        isTurnierStatusErstellt() {
            return store.getters.isTurnierStatusErstellt;
        },
        isTurnierStatusTurnierPlanErstellt() {
            return store.getters.isTurnierStatusTurnierPlanErstellt;
        },
        isTurnierGestartet() {
            return store.getters.isTurnierGestartet;
        },
        isDataLoaded() {
            return store.state.team.isDataLoaded;
        },
    },
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base';
.container {
    margin-bottom: 5px;
}

.description {
    font-size: 1.3rem;
    line-height: 2.3rem;
    margin: 10px 0 15px;
}

.team-detail-item {
    align-items: center;
    display: flex;
    height: 75px;
    margin-left: 25px;
    padding: 15px 85px 15px 45px;
    position: relative;

    .img-container {
        left: -25px;
        margin-right: 15px;
        position: absolute;
        top: 13px;

        .md-avatar {
            border-radius: 5px;
            font-size: 2.2rem;
            height: 50px;
            width: 50px;
        }
    }
    
    .headline {
        display: block;
        font-size: 1.7rem;
        color: $brand-secondary;
    }

    .player {
        font-size: 1.4rem;
        display: flex;

        .player-content {
            margin-top: 10px;
        }

        i.fa-user {
            margin-right: 10px;
            font-size: 1.3rem;
        }

        .player-first {
            margin-right: 20px;
        }
    }
}

.button-list-teams {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.team-button {
    // background-color: #77858D;
    // background-color: #54A1A9;
    // background-color: #9BC2D4;
    // background-color: #FF6F61;
    // background-color: $brand-secondary;
    // background-color: $brand-primary;
    padding: 15px 15px;

    i {
    //   color: $color-white;
      font-size: 1.8rem;
      color: $brand-secondary;
    }

    &:last-child {
        margin-right: 5px;
    }
}

.dialog-content {
    .headline-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;

        .headline {
            margin: 0;
        }
        button {
            padding: 0;
            i {
                color: $brand-primary;
                font-size: 2rem;
            }
        }
    }

    .md-field {
        margin-bottom: 20px;
    }

    .player {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.6rem;
        margin-bottom: 10px;

        .player-content {
            i {
                margin-right: 15px;
            }
        }

        &:last-of-type {
            margin-bottom: 25px;
        }

        &.delete {
            .player-content {
                color: $brand-primary;
                text-decoration: line-through;
            }
        }

        button {
            padding: 15px 15px;

            .fa-minus {
                color: $brand-primary;
            }

            i {
                font-size: 1.6rem;
            }
        }
    }
}

@media (max-width: $screen-xs) {
    .team-detail-item {
        padding-left: 35px;
        height: 80px;
        .headline {
            font-size: 1.5rem;
        }
        .player {
            font-size: 1.3rem;

            .player-first {
                margin-right: 10px;
            }
        }
        .img-container {
            top: 15px;
            .md-avatar {
                font-size: 2rem;
            }
        }
    }
    .description {
        font-size: 1.2rem;
    }
    .team-button {
        padding: 15px 12px;

        i {
            font-size: 1.4rem;
        }

        &:last-child {
            margin-right: 3px;
        }
    }
}

</style>