<template>
    <div class="app-container button-bottom">
        <ListTeamsByTurnier :turnierID="turnierID"/>
        <BottomBarDefault/>
        <md-progress-bar v-if="loadingStatus" md-mode="query"></md-progress-bar>
    </div>
</template>

<script>
import store from '@/store/index'
import ListTeamsByTurnier from '@/components/Teams/ListTeamsByTurnier.vue'
import BottomBarDefault from '@/components/Utils/BottomBarDefault.vue'

export default {
    name: 'ListTeams',
    props: ['turnierID'],
    components: {
        ListTeamsByTurnier,
        BottomBarDefault
    },
    computed: {
        loadingStatus() {
            return store.state.team.loadingStatus;
        },
    },
}
</script>
