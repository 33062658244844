<template>
    <div class="app-container">
        <header>
            <router-link :to="{ name: 'Home'}">
                <button class="btn-unstyled btn-back"><i class="fas fa-chevron-left"></i></button>
            </router-link>
            <h2>{{lang.turnierplan.headline}}</h2>
        </header>
        <div class="container">
            <div v-if="turniere.length == 0 && isDataLoaded">
                <md-empty-state
                    md-icon="sports_bar"
                    :md-label="lang.turnierplan.emptyStateLabelSpiele"
                    :md-description="lang.turnierplan.emptyStateDescriptionSpiele">
                    <router-link :to="{name: 'ListTurniere'}">
                        <md-button class="md-primary md-filled md-round md-raised">{{lang.turnierplan.emptyStateButtonText}}</md-button>
                    </router-link>
                </md-empty-state>
            </div>
            <div v-else>
                <transition appear name="fadeRight" mode="out-in">
                    <ListTurniereComponent 
                        :turniereArray="turniere" 
                        :showAnzTeams="false"
                        routerLinkTo="ListSpiele"/>
                </transition>
            </div>
        </div>
        <BottomBarDefault/>
        <md-progress-bar v-if="loadingStatus" md-mode="query"></md-progress-bar>
    </div>
</template>

<script>
import store from '@/store/index'
import BottomBarDefault from '@/components/Utils/BottomBarDefault.vue'
import ListTurniereComponent from '@/components/Turnier/ListTurniereComponent.vue'
import SpieleView from '@/views/Spiele/Spiele.vue'

export default {
    name: 'SpieleUebersicht',
    components: {
        BottomBarDefault,
        ListTurniereComponent,
        SpieleView
    },
    created() {
        if (Object.keys(store.getters.getSpielerData) == 0) {
            store.dispatch('getSpielerData');
        }
        store.dispatch('getActiveTurniere');
        store.dispatch('getGegruendeteTurniere', false);
    },
    data() {
        return {
            SPIELMODUS: store.getters.getSpielModus,
        }
    },
    computed: {
        lang() {
            return store.getters.getLanguage;
        },
        loadingStatus() {
            return store.state.spiele.loadingStatus;
        },
        turniere() {
            return store.getters.getActiveConcatTurniere;
        },
        isDataLoaded() {
            return store.state.spieler.isSpielerDataLoaded &&
            store.state.spieler.isActiveTurniereArrayLoaded &&
            store.state.spieler.isGruenderTurniereArrayLoaded;
        },
    },
}
</script>

<style lang="scss" scoped>
.md-button {
    display: block;
    margin: 0 auto;
    height: 40px;
    padding: 0 20px;
}
</style>